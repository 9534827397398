/* Prevent title truncate */
[data-selector="headerNav"] a[href^="/"] > span {
	overflow: visible !important;
}

/* Footer */
[data-selector="footer"] {
	border-top: solid 1px #b6bec9;
	margin-top: 5rem;
}

[data-selector="footer"] ul {
	list-style: none;
}

/* Connect */
#amazon-connect-widget-frame {
	margin-bottom: 10px;
}