.markdown-content {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
		Arial, sans-serif;
	line-height: 1.6;
	color: #333;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
	margin-top: 24px;
	margin-bottom: 16px;
	font-weight: 600;
	line-height: 1.25;
}

.markdown-content p {
	margin-bottom: 16px;
}

.markdown-content a {
	color: #0366d6;
	text-decoration: none;
}

.markdown-content a:hover {
	text-decoration: underline;
}

.markdown-content code {
	font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
		monospace;
	padding: 0.2em 0.4em;
	margin: 0;
	font-size: 85%;
	background-color: rgba(27, 31, 35, 0.05);
	border-radius: 3px;
}

.markdown-content pre {
	padding: 16px;
	overflow: auto;
	font-size: 85%;
	line-height: 1.45;
	background-color: #f6f8fa;
	border-radius: 3px;
}

.markdown-content pre code {
	display: inline;
	max-width: auto;
	padding: 0;
	margin: 0;
	overflow: visible;
	line-height: inherit;
	word-wrap: normal;
	background-color: transparent;
	border: 0;
}

.markdown-content ul,
.markdown-content ol {
	padding-left: 2em;
	margin-bottom: 16px;
}

.markdown-content blockquote {
	padding: 0 1em;
	color: #6a737d;
	border-left: 0.25em solid #dfe2e5;
	margin-bottom: 16px;
}

.markdown-content img {
	max-width: 100%;
	box-sizing: content-box;
}
